import { Faqs } from '../components/faqs'


export const About = () => {
    return (
      <>
      <Faqs/>
      </>
    );
  }
  