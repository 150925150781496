import React from 'react'
import { Markets } from '../components/markets';


export const Home = () => {
    return (
      <> 
      <Markets/>
      </>
    );
  }
  
